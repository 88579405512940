import { Box } from "@material-ui/core";
import { useState } from "react";
import Royalties from "../../../components/royalties";
import UserSearch from "../components/userSearch";
import { makeStyles } from "@material-ui/core/styles";
import ScrollToTop from "../../../components/ScrollToTop";

const useStyles = makeStyles((theme) => ({
  searchPanel: {
    position: "fixed",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    // margin: "auto",
    marginTop: theme.spacing(0),
    paddingTop: theme.spacing(11),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    top: 0,
    zIndex: 1000,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(8),
      gap: theme.spacing(4),
    },
  },
}));

const AdminRoyalties = () => {
  const [value, setValue] = useState("");
  const [query, setQuery] = useState("");

  const classes = useStyles();

  const sanitize = (str) => {
    return str.trim().toLowerCase();
  };

  const handleSearch = async () => {
    const sanitized = sanitize(value);
    if (sanitized.length < 4) return;
    setQuery(sanitized);
  };

  const handleChange = ({ target }) => {
    setValue(target.value);
  };

  const clearSearch = () => {
    setValue("");
    setQuery("");
  };

  return (
    <>
      <div className={classes.searchPanel}>
        <ScrollToTop />

        <UserSearch
          handleChange={handleChange}
          handleSearch={handleSearch}
          clearSearch={clearSearch}
          value={value}
        />

        {/* {query && (
          <Typography variant="body">
            <strong>{query}</strong>
          </Typography>
        )} */}
      </div>
      {query && (
        <Box style={{ marginTop: "180px" }}>
          <Royalties query={query} />
        </Box>
      )}
    </>
  );
};

export default AdminRoyalties;
