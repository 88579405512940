import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect } from "react";
import { keys, orderBy } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  chartBox: {
    marginBottom: theme.spacing(5),
  },
}));

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

const getColor = (platform) => {
  switch (platform) {
    case "Spotify":
      return "rgba(29, 185, 84, 1)";
    case "Apple Music":
      return "rgba(0, 0, 0, 1)";
    case "Deezer":
      return "rgb(162, 56, 255)";
    case "YouTube":
      return "rgba(255, 0, 0, 1)";
    case "TikTok":
      return "rgb(239, 42, 80)";
    case "Tidal":
      return "rgba(0, 0, 0, 0.3)";
    case "Napster":
      return "rgba(0, 0, 0, 0.1)";
    case "Pandora":
      return "rgba(0, 0, 0, 0.6)";
    case "Google Play Music":
      return "rgba(0, 0, 0, 0.9)";
    case "KKBox":
      return "rgba(0, 0, 0, 0.2)";
    case "Yandex":
      return "rgba(0, 0, 0, 0.7)";
    case "Spinlet":
      return "rgba(0, 0, 0, 0.4)";
    case "Boomplay":
      return "rgba(0, 0, 0, 0.1)";
    case "Anghami":
      return "rgba(0, 0, 0, 0.5)";
    case "Melon":
      return "rgba(0, 0, 0, 0.8)";
    case "NetEase Cloud Music":
      return "rgba(0, 0, 0, 0.3)";
    case "JioSaavn":
      return "rgba(0, 0, 0, 0.6)";
    case "Gaana":
      return "rgba(0, 0, 0, 0.9)";
    case "Hungama":
      return "rgba(0, 0, 0, 0.2)";
    case "Kuack":
      return "rgba(0, 0, 0, 0.7)";
    case "Boomplay":
      return "rgba  (0, 0, 0, 0.4)";

    default:
      return "rgba(0, 0, 0, 0.1)";
  }
};

const BigChartRoyaltiesbyPlatform = ({ data }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (data) {
      console.log("dat", data);

      const _byPlatform = data.reduce((acc, row) => {
        const { platform, period, cantidad } = row;

        const mainPlatforms = [
          "Spotify",
          "Apple Music",
          "Deezer",
          "YouTube",
          "TikTok",
        ];

        const platGroup = mainPlatforms.includes(platform) ? platform : "Otros";

        if (!acc[platGroup]) {
          acc[platGroup] = {};
        }

        acc[platGroup][period] = cantidad;

        return acc;
      }, {});

      console.log("_byPlatform", _byPlatform);

      const datasets = keys(_byPlatform).map((platform) => {
        return {
          label: platform,
          data: keys(_byPlatform[platform]).map(
            (period) => _byPlatform[platform][period]
          ),
          backgroundColor: getColor(platform),
        };
      });

      const _chartData = {
        labels: data.map((row) => row.periodHuman),
        type: "line",
        datasets,
      };
      setChartData(_chartData);
    }
  }, [data]);

  const classes = useStyles();

  if (!chartData) return null;

  return (
    <div className={classes.root}>
      <Typography variant="h5" gutterBottom>
        Reproducciones por plataforma
      </Typography>

      <Line data={chartData} options={options} />
    </div>
  );
};

export default BigChartRoyaltiesbyPlatform;
