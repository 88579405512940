// import { Chart } from "react-google-charts";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FraudChart from "./FraudChart";
import FraudByTrack from "./FraudByTrack";
import Donut from "./DonutChart";
import HelpFraudChartDialog from "./HelpFraudChartDialog";
import FraudLight from "./FraudLight";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),
    borderRadius: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      borderRadius: theme.spacing(0),
    },
  },
  topTitle: {
    marginTop: theme.spacing(5),
  },
}));

const FraudSection = ({ data }) => {
  const classes = useStyles();

  if (data?.fraudulentTracks?.length === 0) {
    return null;
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h4" gutterBottom className={classes.topTitle}>
          Actividad fraudulenta
        </Typography>

        <FraudLight data={data} />

        <Typography variant="body1" gutterBottom>
          Si estás viendo esta sección es porque las plataformas han detectado y
          reportado actividad fraudulenta en tu contenido.
        </Typography>

        <div style={{ marginTop: ".5em", marginBottom: "2em" }}>
          <HelpFraudChartDialog />
        </div>
      </Paper>
      <Paper className={classes.paper}>
        <FraudChart data={data} />
      </Paper>
      <Paper className={classes.paper}>
        <FraudByTrack data={data} />

        <Donut
          data={data.donut}
          title="Reproducciones fraudulentas por track (detectadas por las plataformas)"
        />
      </Paper>
    </>
  );
};

export default FraudSection;
